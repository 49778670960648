import { fetchConfig } from '../helper/fetch-config';
import { publish } from '../helper/pubsub';
import { PUB_SUB_EVENTS } from '../helper/constants';

/* Variant Select

    - when a variant is selected grab all product variant values
    - with those values generate a URL that will pull the entire PDP
    - with the new PDP re-render the HTML needed
        - Product Media & Info always needs re-render & recharge re initialize
        - Product Editorial will need to render conditionally. 
            -- if metaobject we can see the if the metaobject ID differs.
            -- if metafield we can see if the section exists or not, content difference will be too difficult.

*/
class VariantSelect extends HTMLElement {
  hasInitialized = false;

  constructor() {
    super();
    this.productForm = this.closest('product-form')
    this.productVariants = this.querySelectorAll('product-variant');

    this.selectedVariantOption = this.querySelectorAll(
      'input[name="selected-variant-option"]'
    );
    this.selectedVariantId = this.querySelectorAll(
      'input[name="selected-variant-id"]'
    );

    this.globalProductId = this.productForm.querySelector(
      'input[name="product-id"]'
    ).value;
  }

  connectedCallback() {
    if (this.hasInitialized) return;
    this.hasInitialized = true;
    
    this.initializeVariantSelection();
    this.applyEventListeners();
    this.applySubscriptionUI();
  }

  initializeVariantSelection() {
    // Find which size is visually selected
    const selectedSizeVariant = this.querySelector('product-variant.variant-selected-size');
    if (!selectedSizeVariant) return;
    
    // Get the URL and ID from the selected variant
    const variantUrl = selectedSizeVariant.getAttribute('variant-value');
    
    // Ensure the #selected-size input has the full URL including size
    const selectedSizeInput = this.querySelector('#selected-size');
    if (selectedSizeInput && variantUrl) {
      // Only update if the input doesn't already have a value that includes size
      const currentValue = selectedSizeInput.value;
      const hasSize = /(\d+-oz|\d+oz|\d+ml|d+-ml|\d+pack|d+-pack)/.test(currentValue);
      
      if (!hasSize) {
        selectedSizeInput.value = variantUrl;
        console.log('Initialized selected-size input with current selection:', variantUrl);
      }
    }
  }

  applyEventListeners() {
    [...this.productVariants].forEach((variant) => {
      variant.addEventListener('click', this.onVariantChange.bind(this));
    });
  }

  applySubscriptionUI() {
    // Update selectors to match the actual DOM structure
    const subscriptionRadio = document.querySelector('input[type="radio"][data-purchase-type-radio][value="subscription"]');
    const oneTimeRadio = document.querySelector('input[type="radio"][data-purchase-type-radio][value="one-time"]');
    const subscriptionSelect = document.querySelector('.subscription-options');

    // Check initial state and show/hide select accordingly
    if (subscriptionRadio && subscriptionRadio.checked) {
      subscriptionSelect?.classList.remove('hidden');
    } else {
      subscriptionSelect?.classList.add('hidden');
    }

    // Clean up subscription select options
    if (subscriptionSelect) {
      const modifiedOptions = Array.from(subscriptionSelect.querySelectorAll('option')).map(option => {
        const text = option.text;
        const deliveryText = text.split('-')[0].trim();
        option.text = deliveryText;
        return option;
      });
    }

    // Update selectors to match the actual DOM structure
    const priceSpan = document.querySelector('[data-price-id^="price-"]');
    const addToCartButton = document.querySelector('button[name="add"]');
    
    if (subscriptionRadio && oneTimeRadio && addToCartButton) {
      // Store original price for resetting
      const originalPriceSpan = addToCartButton.querySelector('[data-price-id^="price-"]');
      const originalPrice = originalPriceSpan?.textContent.match(/\$(\d+(?:\.\d{1,2})?)/)?.[1];

      const updateSubscriptionSelect = () => {
        const isSubscriptionSelected = subscriptionRadio.checked;

        // Use querySelector to find the select, but don't redeclare the variable
        const select = document.querySelector('.subscription-options select') || 
                      document.querySelector('select[data-subscription-select]');

        if (select) {
          // Update the select's name based on subscription state
          select.name = isSubscriptionSelected ? 'selling_plan' : '';
        }
      };

      const updateAddToCartPrice = () => {
        const isSubscriptionSelected = subscriptionRadio.checked;
        const productIdInput = document.querySelector('input[name="id"]');
        const productId = document.querySelector('input[name="product-id"]')?.value;
        
        const discountPercent = window.subscriptionDiscounts?.[productId] || 5;
        const discountMultiplier = (100 - discountPercent) / 100;

        const oneTimeLabel = document.querySelector('label[for^="one-time-"]');
        
        const priceText = oneTimeLabel?.textContent.match(/\$(\d+(?:\.\d{2})?)/)?.[1];
        
        const stickyButton = document.querySelector('sticky-atc button[name="add"]');
        const stickyPriceSpan = stickyButton?.querySelector('[data-price-id^="price-"]');
        
        if (!priceSpan || !priceText) {
          return;
        }

        const basePrice = parseFloat(priceText);
        const finalPrice = isSubscriptionSelected ? 
          (basePrice * discountMultiplier).toFixed(2) : 
          basePrice.toFixed(2);
        
        // Keep button text as "Add to bag" for both states
        const buttonText = 'Add to bag';
        const priceId = priceSpan.getAttribute('data-price-id');
        
        addToCartButton.innerHTML = `
          ${buttonText}&nbsp;—&nbsp;
          <span data-price-id="${priceId}">$${finalPrice}</span>
        `;

        // Update sticky button if it exists
        if (stickyButton && stickyPriceSpan) {
          const stickyPriceId = stickyPriceSpan.getAttribute('data-price-id');
          stickyButton.innerHTML = `
            ${buttonText}&nbsp;—&nbsp;
            <span data-price-id="${stickyPriceId}">$${finalPrice}</span>
          `;
        }
      };

      // Log form state
      const form = addToCartButton.closest('form');

      // Initial updates
      updateAddToCartPrice();

      // Add event listeners for future changes
      subscriptionRadio.addEventListener('change', (e) => {
        updateAddToCartPrice();
      });
      
      oneTimeRadio.addEventListener('change', (e) => {
        updateAddToCartPrice();
      });
    }

    // Add radio background styling
    const radioContainers = document.querySelectorAll('[data-radio-container]');
    function updateRadioBackgrounds() {
      radioContainers.forEach(container => {
        const radio = container.querySelector('input[type="radio"]');
        container.classList.toggle('bg-white', radio.checked);
        container.classList.toggle('bg-brand-secondary-50', !radio.checked);
      });
    }

    // Set initial state
    updateRadioBackgrounds();

    // Update on change
    radioContainers.forEach(container => {
      const radio = container.querySelector('input[type="radio"]');
      radio.addEventListener('change', updateRadioBackgrounds);
    });
  }

  async renderProductInformation(targetValue) {
    /*this part of the code assumes URLS are formatted as the following
    /products/[product-title]-[product-size]:
    EG: new-wash-original-8-oz;
    */
    try {
      const response = await fetch(targetValue);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if (window.location.pathname.includes('/products')) {
        history.pushState({}, '', targetValue);
      }

      const data = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');

      const updatedMedia = doc.querySelector('product-media');
      const updatedTitle = doc.querySelector('#productTitle');
      const updatedForm = doc.querySelector('#product-atc');
      const updatedInfo = doc.querySelector('tab-group');
      const updatedProductRecs = doc.querySelector('recommended-products');
      const updatedProductId = doc.querySelector('input[name="product-id"]');
      const updatedBreadCrumb = doc.querySelector('.breadcrumbs');
      const updatedIngredientsSlider = doc.querySelector('#content-carousel');
      const updatedFAQs = doc.querySelector('#editorial-faq');
      const updatedEditorialGalleries = doc.querySelector('#product-editorial-galleries');

      if (updatedProductId) {
        const currentProductId = this.productForm.querySelector('input[name="product-id"]');
        if (currentProductId && currentProductId.value !== updatedProductId.value) {
          const reviewWidget = document.querySelector('.yotpo-widget-instance[data-yotpo-instance-id="476744"]');
          
          if (reviewWidget) {
            const updatedProductData = {
              title: doc.querySelector('h1')?.textContent?.trim(),
              url: window.location.pathname,
              price: doc.querySelector('[data-product-price]')?.getAttribute('data-product-price'),
              image: doc.querySelector('product-media img')?.src,
              description: doc.querySelector('meta[name="description"]')?.content,
              id: updatedProductId.value
            };
      
            const newWidget = document.createElement('div');
            newWidget.className = 'yotpo-widget-instance';
            newWidget.setAttribute('data-yotpo-instance-id', '476744');
            newWidget.setAttribute('data-yotpo-product-id', updatedProductData.id);
            newWidget.setAttribute('data-yotpo-name', updatedProductData.title || '');
            newWidget.setAttribute('data-yotpo-url', window.location.origin + updatedProductData.url);
            newWidget.setAttribute('data-yotpo-image-url', updatedProductData.image || '');
            newWidget.setAttribute('data-yotpo-price', updatedProductData.price || '');
            newWidget.setAttribute('data-yotpo-currency', reviewWidget.getAttribute('data-yotpo-currency')); // Keep existing currency
            newWidget.setAttribute('data-yotpo-description', updatedProductData.description || '');
      
            const widgetParent = reviewWidget.parentNode;
            reviewWidget.remove();
      
            widgetParent.appendChild(newWidget);
      
            yotpoWidgetsContainer.initWidgets()
          } else {
            console.log('Review widget not found');
          }
        }
      }

      if (updatedIngredientsSlider) {
        const currentIngredientsSlider = document.querySelector('#content-carousel');
        if (currentIngredientsSlider) {
          currentIngredientsSlider.innerHTML = updatedIngredientsSlider.innerHTML;
        }
      }
      if (updatedFAQs) {
        const currentFAQs = document.querySelector('#editorial-faq');
        if (currentFAQs) {
          currentFAQs.innerHTML = updatedFAQs.innerHTML;
        }
      }

      if (updatedEditorialGalleries) {
        const currentEditorialGalleries = document.querySelector('#product-editorial-galleries');
        if (currentEditorialGalleries) {
          currentEditorialGalleries.innerHTML = updatedEditorialGalleries.innerHTML;
          const newGalleries = currentEditorialGalleries.querySelector('product-editorial-galleries');
          if (newGalleries) {
            customElements.upgrade(newGalleries);
          }
        }
      }

      if (updatedBreadCrumb) {
        const currentBreadcrumb = document.querySelector('.breadcrumbs');
        if (currentBreadcrumb) {
          currentBreadcrumb.innerHTML = updatedBreadCrumb.innerHTML;
        }
      }

      if (updatedMedia) {
        const currentSection = document.querySelector('product-media');
        const upgradedMedia = document.querySelector('product-media');
        if(currentSection.getAttribute('source') == "featured-product"){
          const updatedImg = updatedMedia.querySelector('img');
          updatedImg.classList.remove('aspect-square', 'object-container');
          updatedImg.classList.add('aspect-[5/7]', 'object-cover');
          currentSection.querySelector('img').replaceWith(updatedImg);
        } else {
          currentSection.replaceWith(updatedMedia);
          customElements.upgrade(upgradedMedia);
          upgradedMedia.generateCarousel();
        }
      
      } else {
        console.error('Updated section not found in the response');
      }

      if (updatedTitle) {
        const currentTitle = document.querySelector('#productTitle');
        currentTitle.innerHTML = updatedTitle.innerHTML;
      }

      if (updatedForm) {
        const currentForm = document.querySelector('#product-atc');
        if (!currentForm.querySelector('sticky-atc')) {
          updatedForm.querySelector('sticky-atc').remove();
        }
        currentForm.innerHTML = updatedForm.innerHTML;

        // Wait for subscription UI initialization
        const checkForSubscriptionUI = setInterval(() => {
          const bulletsContainer = document.querySelector('.subscription-bullets-container');
          
          if (bulletsContainer && window.updateSubscriptionBullets) {
            clearInterval(checkForSubscriptionUI);
            const beforeHTML = bulletsContainer.innerHTML;
            window.updateSubscriptionBullets(updatedProductId.value);
            const afterHTML = bulletsContainer.innerHTML;
          }
        }, 100);

        // Clear interval after 5 seconds to prevent infinite checking
        setTimeout(() => clearInterval(checkForSubscriptionUI), 5000);

        // Parse the full response HTML to get the new elements
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = data;
        const newProductId = updatedProductId.value;

        // Get current subscription state before updating form
        const currentRadio = document.querySelector(`input[type="radio"][name="purchase_type"]:checked`);
        const wasSubscriptionSelected = currentRadio?.value === 'subscription';
        
        // Find all one-time price labels in the current form
        const currentOneTimeLabel = document.querySelector('label[for^="one-time-"]');
        const newOneTimeLabel = tempDiv.querySelector(`label[for="one-time-${newProductId}"]`);
        
        if (currentOneTimeLabel && newOneTimeLabel) {
          const newPrice = newOneTimeLabel.textContent.match(/\$(\d+(?:\.\d{2})?)/)?.[1];
          if (newPrice) {
            currentOneTimeLabel.innerHTML = `One-time purchase <span class="sr-only">- $${newPrice}</span>`;
          }
        }

        // Find the new subscription elements in the parsed response
        const newSubscriptionSelect = tempDiv.querySelector(`#selling_plan_${newProductId}`);
        
        if (newSubscriptionSelect) {
          // Find the current select in the DOM
          const currentSelect = document.querySelector('select[id^="selling_plan_"]');
          
          if (currentSelect) {
            // Create new select with updated ID and content
            const updatedSelect = document.createElement('select');
            updatedSelect.id = `selling_plan_${newProductId}`;
            updatedSelect.className = currentSelect.className;
            updatedSelect.name = wasSubscriptionSelected ? 'selling_plan' : '';
            
            // Modify the options to only show delivery intervals
            const options = Array.from(newSubscriptionSelect.options);
            
            const modifiedOptions = options.map(option => {
              const text = option.text;
              // Extract just the delivery interval part
              const deliveryText = text.split('-')[0].trim(); // This will get "Delivery every X Weeks"
              
              const newOption = document.createElement('option');
              newOption.value = option.value;
              newOption.text = deliveryText;
              return newOption;
            });
            
            // Add modified options to select
            modifiedOptions.forEach(option => updatedSelect.appendChild(option));
            
            // Replace the old select with the new one
            currentSelect.parentNode.replaceChild(updatedSelect, currentSelect);
          }
        }

        // Update the form content
        currentForm.innerHTML = updatedForm.innerHTML;

        // After form update, restore subscription state
        const subscriptionRadio = document.getElementById(`subscription-${newProductId}`);
        const oneTimeRadio = document.getElementById(`one-time-${newProductId}`);
        
        if (subscriptionRadio && oneTimeRadio) {
          const updateAddToCartPrice = () => {
            const priceText = document.querySelector('label[for^="one-time-"]')?.textContent.match(/\$[\d.]+/)?.[0];
            const isSubscriptionSelected = subscriptionRadio.checked;
            
            if (priceText) {
              const basePrice = parseFloat(priceText.replace('$', ''));
              const discountPercent = window.subscriptionDiscounts?.[newProductId] || 5;
              const discountMultiplier = (100 - discountPercent) / 100;
              const finalPrice = isSubscriptionSelected ? 
                (basePrice * discountMultiplier).toFixed(2) : 
                basePrice.toFixed(2);
              
              // Update the price span in the add to cart button
              const priceSpan = document.querySelector(`[data-price-id^="price-"]`);
              if (priceSpan) {
                priceSpan.textContent = `$${finalPrice}`;
              }

              // Also update the button text if needed
              const button = priceSpan?.closest('button');
              if (button) {
                const buttonText = `
                  Add to bag&nbsp;—&nbsp;
                  <span data-price-id="${priceSpan.getAttribute('data-price-id')}">$${finalPrice}</span>
                `;
                
                // Update main button
                button.innerHTML = buttonText;
                
                // Update sticky button if it exists
                const stickyButton = document.querySelector('sticky-atc button[name="add"]');
                if (stickyButton) {
                  stickyButton.innerHTML = buttonText;
                }
              }
            }
          };

          // Update price immediately and add listeners for future changes
          updateAddToCartPrice();
          
          // Add event listeners for radio button changes
          subscriptionRadio.addEventListener('change', () => {
            updateAddToCartPrice();
          });
          
          oneTimeRadio.addEventListener('change', () => {
            updateAddToCartPrice();
          });
        }
      }

      if (updatedInfo) {
        const currentInfo = document.querySelector('tab-group');
        if (currentInfo) {
          currentInfo.replaceWith(updatedInfo);
          const upgradedInfo = document.querySelector('tab-group');
          customElements.upgrade(upgradedInfo);
          //upgradedInfo.openFirstDetails();
        }
      }

      if (updatedProductRecs) {
        const currentProductRecs = document.querySelector(
          'recommended-products'
        );
        if (currentProductRecs) {
          currentProductRecs.innerHTML = updatedProductRecs.innerHTML;
        }
      }

      if (updatedProductId) {
        const currentProductId = this.productForm.querySelector(
          'input[name="product-id"]'
        );

        let shouldRemountRechargeWidget =
          currentProductId.value !== updatedProductId.value;

        if (shouldRemountRechargeWidget && window.HairstoryRechargeWidget) {
          // Fix: Add null check before accessing onNewProductSelect
          const previousWidget = window.HairstoryRechargeWidget[currentProductId.value];
          if (previousWidget && typeof previousWidget.onNewProductSelect === 'function') {
            previousWidget.onNewProductSelect(updatedProductId.value);
          } else {
            console.log(`RechargeWidget not available for product ${currentProductId.value} - skipping remount`);
          }
        }

        if (currentProductId) {
          currentProductId.value = updatedProductId.value;
        }
      }

      setTimeout(() => {
        // Store subscription state before updating DOM elements
        const currentRadio = document.querySelector(`input[type="radio"][name="purchase_type"]:checked`);
        const wasSubscriptionSelected = currentRadio?.value === 'subscription';
        
        // Restore subscription selection
        const newRadios = document.querySelectorAll('input[data-purchase-type-radio]');
        if (newRadios.length > 0) {
          newRadios.forEach(radio => {
            if ((radio.value === 'subscription' && wasSubscriptionSelected) || 
                (radio.value === 'one-time' && !wasSubscriptionSelected)) {
              radio.checked = true;
              // Trigger change event to update UI
              radio.dispatchEvent(new Event('change', { bubbles: true }));
            }
          });
          
          // Update the selling plan select name after restoring radio state
          if (!wasSubscriptionSelected) {
            // If one-time was selected, set selling_plan select name to empty
            const form = newRadios[0]?.closest('form');
            if (form) {
              // Find both possible selling plan selects
              const idPrefixSelect = form.querySelector('select[id^="selling_plan_"]');
              if (idPrefixSelect) {
                idPrefixSelect.name = '';
              }
              
              const subscriptionOptions = form.querySelector('.subscription-options');
              const subscriptionSelect = subscriptionOptions?.querySelector('select');
              if (subscriptionSelect) {
                subscriptionSelect.name = '';
              }
            }
          }
        }
      }, 100);
    } catch (error) {
      console.error('Error fetching & updated section:', error);
    }
  }

  onVariantChange(event) {
    event.preventDefault();
    let isVariantType = false;
    [...this.productVariants].forEach((variant) => {
      if (variant.classList.contains('variant-selected-type')) {
        isVariantType = true;
      }
    });
    if (isVariantType) {
      [...this.productVariants].forEach((variant) =>
        variant.classList.remove('variant-selected-type')
      );
      event.target.classList.add('variant-selected-type');
    } else {
      [...this.productVariants].forEach((variant) =>
        variant.classList.remove('variant-selected-size')
      );
      event.target.classList.add('variant-selected-size');
    }

    //variant-value is the main target for the value of the product options.
    //variant-id returns the variant ID of the selected size product & the product ID of the product type;
    let targetValue = event.target.getAttribute('variant-value');
    let targetId = event.target.getAttribute('variant-id');

    this.selectedVariantOption.forEach((option) => {
      option.value = targetValue;
    });
    this.selectedVariantId.forEach((option) => {
      option.value = targetId;
    });

    let targetSize = this.productForm.querySelector('#selected-size').value;
    let match = targetSize.match(/(\d+-oz|\d+oz|\d+ml|d+-ml|\d+pack|d+-pack)/);
    let sizeToReplace = match ? match[1] : null;

    if (sizeToReplace) {
      // Replace the `xx-oz` part in the second URL
      targetValue = targetValue.replace(
        /(\d+-oz|\d+oz|\d+ml|d+-ml|\d+pack|d+-pack)/,
        sizeToReplace
      );
    } else {
      console.log('No size found in the first URL to replace.');
    }

    this.renderProductInformation(targetValue);
  }
}

if (!window.customElements.get('variant-select')) {
  window.customElements.define('variant-select', VariantSelect);
}

class VariantSelectCard extends VariantSelect {
  constructor() {
    super();

    this.productForm = this.closest('product-form');
    this.productVariants = this.querySelectorAll('product-variant');
    this.selectedVariantOption = this.querySelector(
      'input[name="selected-variant-option"]'
    );
    this.selectedVariantId = this.querySelector(
      'input[name="selected-variant-id"]'
    );
    this.variantIdInput = this.productForm.querySelector('input[name="id"]');
    this.priceElements = null;
    this.productIdInputs = null;
  }

  connectedCallback() {
    this.applyEventListeners();
  }

  applyEventListeners() {
    this.productVariants.forEach((variant) => {
      variant.addEventListener('click', this.onVariantChange.bind(this));
    });
  }

  async renderProductInformation() {
    this.variantIdInput.value = this.selectedVariantId.value;
  }

  onVariantChange(event) {
    event.preventDefault();

    this.productVariants.forEach((variant) =>
      variant.classList.remove('variant-selected-size')
    );
    event.target.classList.add('variant-selected-size');

    let targetProductHandle = event.target.getAttribute('variant-value');
    let targetVariantId = event.target.getAttribute('variant-id');

    this.selectedVariantOption.value = targetProductHandle;
    this.selectedVariantId.value = targetVariantId;
    this.variantIdInput.value = targetVariantId;

    this.fetchVariantData(targetProductHandle, targetVariantId);
  }

  async fetchVariantData(productHandle, variantId) {
    try {
      // Use previousProductId directly from the query, don't redeclare
      const previousProductId = this.productForm.querySelector(
        'input[name="product-id"]'
      ).value;
      const response = await fetch(`${productHandle.split('?')[0]}.js`);

      if (!response.ok) throw new Error('Network response was not ok');

      const productData = await response.json();

      const selectedVariant = productData.variants.find(
        (variant) => variant.id.toString() === this.selectedVariantId.value
      );

      const newProductId = String(productData.id);

      if (selectedVariant) {
        // Immutably update productIdInputs
        this.productIdInputs = Array.from(
          document.querySelectorAll('input[name="product-id"]')
        );

        // Immutably update priceElements
        this.priceElements = Array.from(
          document.querySelectorAll(
            `[data-price-id="price-${previousProductId}"]`
          )
        );

        if (this.priceElements.length > 0) {
          // Immutably update priceElements
          this.priceElements = this.priceElements.map((priceElement) => {
            const clonedElement = priceElement.cloneNode(true);
            clonedElement.setAttribute(
              'data-price-id',
              `price-${newProductId}`
            );

            let priceText = document.createElement('span');
            let compareText = document.createElement('span');
            compareText.classList.add('line-through', 'text-brand-secondary-350');

            priceText.textContent = this.formatMoney(selectedVariant.price);

            if(selectedVariant.compare_at_price) {
              const compareAtPrice = this.formatMoney(selectedVariant.compare_at_price);
              compareText.textContent =  `${compareAtPrice}`
              priceText.prepend("\u00A0")
              priceText.prepend(compareText);
            }

            clonedElement.textContent = "";
            clonedElement.append(priceText)
            return clonedElement;
          });

          // Replace old elements with new ones in the DOM
          this.priceElements.forEach((newElement, index) => {
            const oldElement = document.querySelector(
              `[data-price-id="price-${previousProductId}"]`
            );
            if (oldElement) {
              oldElement.parentNode.replaceChild(newElement, oldElement);
            }
          });
        }

        // If the user clicked on the same variant, we don't want to remount the options container
        if (newProductId !== previousProductId) {
          let deliveryOptionsContainer = this.productForm.querySelector(
            `#delivery-options-${previousProductId}`
          );
          let cardAtcContainer = document.querySelector(
            `#card-atc-${previousProductId}`
          );

          if (deliveryOptionsContainer) {
            deliveryOptionsContainer.id = `delivery-options-${newProductId}`;
            deliveryOptionsContainer.innerHTML = '';
          }

          if (cardAtcContainer) {
            cardAtcContainer.id = `card-atc-${newProductId}`;
          }
        }

        if (previousProductId && previousProductId !== newProductId) {
          let shouldRemountRechargeWidget = true;
          if (shouldRemountRechargeWidget && window.HairstoryRechargeWidget) {
            window.HairstoryRechargeWidget[
              previousProductId
            ].onNewProductSelect(newProductId);
          }
          if (this.productIdInputs.length > 0) {
            // Immutably update productIdInputs
            this.productIdInputs = this.productIdInputs.map((input) => {
              const clonedInput = input.cloneNode(true);
              clonedInput.value = newProductId;
              return clonedInput;
            });

            // Replace old inputs with new ones in the DOM
            this.productIdInputs.forEach((newInput) => {
              const previousInput = document.querySelector(
                'input[name="product-id"]'
              );
              if (previousInput) {
                previousInput.parentNode.replaceChild(newInput, previousInput);
              }
            });
          }
        }

        this.variantIdInput.value = variantId;
        this.selectedVariantId.value = variantId;

        const productFormIdInput = this.productForm.querySelector(
          'input[name="product-id"]'
        );

        if (productFormIdInput) {
          productFormIdInput.value = newProductId;
        }
      }
    
    } catch (error) {
      console.error('Error fetching variant data:', error);
    }
  }

  formatMoney(cents) {
    return '$' + (cents / 100).toFixed(2);
  }
}

if (!window.customElements.get('variant-select-card')) {
  window.customElements.define('variant-select-card', VariantSelectCard);
}

class VariantSelectSection extends VariantSelect {
  constructor() {
    super();
  }
}

if (!window.customElements.get('variant-select-section')) {
  window.customElements.define('variant-select-section', VariantSelectSection);
}

class ProductVariant extends HTMLElement {
  constructor() {
    super();
  }
}

if (!window.customElements.get('product-variant')) {
  window.customElements.define('product-variant', ProductVariant);
}

class ProductForm extends HTMLElement {
  constructor() {
    super();
    this.form = this.querySelector('form');
    this.cart = document.querySelector('cart-drawer');
  }

  connectedCallback() {
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
  }

  onSubmitHandler(event) {
    event.preventDefault();
    // if (this.submitButton.getAttribute('aria-disabled') === 'true') return;

    this.handleErrorMessage();

    const config = fetchConfig('javascript');
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    delete config.headers['Content-Type'];

    const id = parseInt(this.form.querySelector('input[name="id"]').value);
    let selling_plan;

    if (this.form.querySelector('select[name="selling_plan"]')) {
      selling_plan = parseInt(
        this.form.querySelector('select[name="selling_plan"]').value
      );
    }

    let formData = {
      items: [
        {
          id,
          quantity: 1,
          ...(selling_plan && { selling_plan })
        }
      ],
      sections: this.cart.getSectionsToRender().map((section) => section.id)
    };

    this.cart.setActiveElement(document.activeElement);

    config.method = 'POST';
    config.headers = {
      'Content-Type': 'application/json'
    };
    config.body = JSON.stringify(formData);

    fetch(window.Shopify.routes.root + 'cart/add.js', config)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          publish(PUB_SUB_EVENTS.cartError, {
            source: 'product-form',
            productVariantId: formData.items[0].id,
            errors: response.errors || response.description,
            message: response.message
          });
          this.handleErrorMessage(response.description);

          const soldOutMessage =
            this.submitButton.querySelector('.sold-out-message');
          if (!soldOutMessage) return;
          this.submitButton.setAttribute('aria-disabled', true);
          this.submitButtonText.classList.add('hidden');
          soldOutMessage.classList.remove('hidden');
          this.error = true;
          return;
        } else if (!this.cart) {
          window.location = window.routes.cart_url;
          return;
        }

        if (!this.error)
          publish(PUB_SUB_EVENTS.cartUpdate, {
            source: 'product-form',
            productVariantId: formData.items[0].id,
            cartData: response
          });
        this.error = false;
        this.cart.renderContents(response);
        this.cart.drawerOpenAnimation(this.cart.querySelector('details'));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (this.cart && this.cart.classList.contains('is-empty'))
          this.cart.classList.remove('is-empty');
      });
  }

  handleErrorMessage(errorMessage = false) {
    if (this.hideErrors) return;

    this.errorMessageWrapper =
      this.errorMessageWrapper ||
      this.querySelector('.product-form__error-message-wrapper');
    if (!this.errorMessageWrapper) return;
    this.errorMessage =
      this.errorMessage ||
      this.errorMessageWrapper.querySelector('.product-form__error-message');

    this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);

    if (errorMessage) {
      this.errorMessage.textContent = errorMessage;
    }
  }
}

if (!window.customElements.get('product-form')) {
  window.customElements.define('product-form', ProductForm);
}

class StickyATC extends HTMLElement {
  constructor() {
    super();
    this.addToBagButton =
      this.closest('#product-atc').querySelector('button[name="add"]');
    this.headerHeight = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--header-height');
  }

  connectedCallback() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            this.classList.add('lg:top-[var(--header-height)]');
            this.classList.add('lg:bottom-auto');
            this.classList.remove('lg:bottom-0');
          } else {
            this.classList.remove('lg:top-[var(--header-height)]');
            this.classList.add('lg:bottom-0');
            this.classList.remove('lg:bottom-auto');
          }
        });
      },
      {
        root: null,
        rootMargin: `-${this.headerHeight} 0px 0px 0px`,
        threshold: 0
      }
    );

    observer.observe(this.addToBagButton);
  }
}

if (!window.customElements.get('sticky-atc')) {
  window.customElements.define('sticky-atc', StickyATC);
}
